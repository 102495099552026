<template>
  <v-app id="inspire">
    <v-container>
      <top-bar :navpos="items" />
      <v-main>
        <v-toolbar flat>
          <help />
          <v-toolbar-title>{{ $t("entity.heading") }}</v-toolbar-title>
        </v-toolbar>
        <p>
          <span v-html="$t('entity.short')"></span>
          <br />
          <br />
          <span v-html="$t('entity.long')"></span>
        </p>
        <v-row>
          <v-spacer />
          <v-col cols="12" sm="6" md="6">
            <v-form ref="form_enity">
              <v-row>
                <v-col cols="12" sm="6" md="4">
                  <v-select
                    :items="entities"
                    v-model="entityRoute"
                    :label="$t('entity.label')"
                    item-text="text"
                    item-value="route"
                  ></v-select>
                </v-col>
              </v-row>
            </v-form>
          </v-col>
          <v-col cols="12" sm="6" md="6" class="text-left">
            <v-btn color="primary" dark @click="save">
              {{ $t("common.button.start") }}
            </v-btn>
          </v-col>
        </v-row>
      </v-main>
      <footer-bar />
    </v-container>
  </v-app>
</template>

<script>
import TopBar from "../../components/TopBar.vue";
import FooterBar from "../../components/Footer.vue";
import Help from "@/components/help/EntityHelp.vue";

export default {
  components: { TopBar, FooterBar, Help },
  name: "Main",
  props: {},

  data() {
    return {
      items: [
        {
          text: this.$t("nav.main"),
          disabled: false,
          to: { path: "/main" }
        },
        {
          text: this.$t("nav.entity"),
          disabled: true,
          href: "main"
        }
      ],
      entities: [
				{
				route: "/entity/projekt",
				text: this.$t("entity.list.projekt")
				}
,
				{
				route: "/entity/lieferant",
				text: this.$t("entity.list.lieferant")
				}
,
				{
				route: "/entity/nameOfSuperClass",
				text: this.$t("entity.list.nameOfSuperClass")
				}
,
				{
				route: "/entity/dateTest",
				text: this.$t("entity.list.dateTest")
				}

//end here
      ],
      entityRoute: {}
    };
  },
  methods: {
    save() {
      try {
        this.$router.push({ path: this.entityRoute });
      } catch (error) {
        this.msg = error.response.data.msg;
      }
    }
  },
  computed: {}
};
</script>
